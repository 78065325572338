import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "searchPill",
  class: "search-pill-button m-0 flex justify-center items-center leading-loose w-auto whitespace-nowrap cursor-pointer",
  "search-pill": ""
}
const _hoisted_2 = { class: "text-[13px] px-0 max-h-full mb-0 mx-2 select-none cursor-pointer font-search-pill-font-weight font-sparkfont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.name), 1 /* TEXT */)
  ], 512 /* NEED_PATCH */))
}